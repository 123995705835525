<template>
  <div>
    <div id="Header">
      <h3>Bookmark Page</h3>
    </div>
    <div class="container">
      <!-- Empty State -->
      <div v-if="!hasData" class="empty-state">
        <img :src="emptyImg" alt="No Bookmarks" />
        <p>No Bookmarks Yet!</p>
        <p>
          It looks like you haven't Archieve any files or cases.
          Start exploring and bookmark your favorites for quick access.
        </p>
      </div>

      <!-- Cards Grid -->
      <div class="cards-grid mt-4">
        <div class="bookmark-grid">
            <div
            v-for="(item, index) in bookmarks"
            :key="index"
            class="bookmark-item"
            @click="navigateTo(item.routeName)"
            :class="{ 'loading-background': pageLoading }"
            >
            <div class="icon-wrapper">
                <span
                class="material-symbols-rounded icon"
                :style="{ color: item.color, background: item.background }"
                :class="{ 'fade-out': pageLoading }"
                >
                {{ item.icon }}
                </span>
                <span class="count" :class="{ 'fade-out': pageLoading }">
                {{ item.count || 0 }}
                </span>
            </div>
            <div class="bookmark-details mt-4" :class="{ 'fade-out': pageLoading }">
                <p>{{ item.title }}</p>
            </div>
            </div>
        </div>
    </div>
    </div>
  </div>
</template>

<script>
import emptyImg from '@/assets/misc/empty.svg';
import general from '../../store/general';
export default {
  data() {
    return {
      pageLoading:true,
      emptyImg,
      bookmarks: [
        {
            title: 'Legal AI Trial Copilot',
            count: '',
            icon: 'gavel',
            routeName: 'BookmarkCopilot',
            color: "#1890FF",
            background: 'rgba(16, 137, 255, 0.1)',
            type: ['les']
        },
        {
            title: 'Legal Advice',
            count: this.argument_total + this.memo_total,
            icon: 'contract',
            routeName: 'BookmarkAdvice',
            color: "#FAAD14",
            background: 'rgba(255, 163, 25, 0.1)',
            type: ['argument', 'memo']
        },
        {
            title: 'Case Law Summarizer',
            count: '',
            icon: 'description',
            routeName: 'BookmarkSummarizer',
            color: "#FF4D4F",
            background: 'rgba(240, 87, 87, 0.1)',
            type: ['case_summarizer']
        },
        {
            title: 'Legal Research',
            count: this.statement_total + this.legislation_total,
            icon: 'find_in_page',
            routeName: 'BookmarkResearch',
            color: "#383A3E",
            background: 'rgba(15, 50, 82, 0.1)',
            type: ['statement', 'legislation']
        }
        ]

    };
  },
  computed: {
    hasData() {
      return this.bookmarks.length > 0;
    },
  },
  methods: {
     updateBookmarkCounts() {
      this.bookmarks.forEach((bookmarks) => {
        const args = { type: bookmarks.type};
        general.GetBookmarkedCampaigns(args)
          .then((response) => {
            const data = response.data.data;
            bookmarks.count = data.total;
            this.pageLoading=false;
            
          })
          .catch((error) => {
            console.error("Failed to fetch archived campaigns", error);
          });
      });
    },

    /* API data to count data’s inside table is not correct 
       the problems have to do with the types can only get 
       1 Value of Total, so I need to add 1 more API to get to Add both Values
    */
    //Get the count of bookmarked legislation items
    getLegislationCounts() {
      const args = { type: ['legislation'] };
      general.GetBookmarkedCampaigns(args)
        .then((response) => {
          const data = response.data.data;
          this.legislation_total = data.total;
          this.pageLoading = false;
        })
        .catch((error) => {
          console.error("Failed to fetch archived campaigns", error);
        });
    },

    getStatementCounts() {
      const args = { type: ['statement'] };
      general.GetBookmarkedCampaigns(args)
        .then((response) => {
          const data = response.data.data;
          this.statement_total = data.total;
          this.pageLoading = false;
        })
        .catch((error) => {
          console.error("Failed to fetch archived campaigns", error);
        });
    },

    getMemoCounts() {
      const args = { type: ['memo'] };
      general.GetBookmarkedCampaigns(args)
        .then((response) => {
          const data = response.data.data;
          this.memo_total = data.total;
          this.pageLoading = false;
        })
        .catch((error) => {
          console.error("Failed to fetch archived campaigns", error);
        });
    },

    getArgumentCounts() {
      const args = { type: ['argument'] };
      general.GetBookmarkedCampaigns(args)
        .then((response) => {
          const data = response.data.data;
          this.argument_total = data.total;
          this.pageLoading = false;
        })
        .catch((error) => {
          console.error("Failed to fetch archived campaigns", error);
        });
    },

    navigateTo(routeName) {
      this.$router.push({ name: routeName });
    },
  },
  created() {
    this.getStatementCounts();
    this.getLegislationCounts();
    this.getArgumentCounts();
    this.getMemoCounts();
    this.updateBookmarkCounts();
  }
};
</script>

<style scoped>
#Header {
  margin: -20px -20px 0px -20px;
  padding: 16px 20px;
  height: 72px;
  display: flex;
  align-items: center;
  background-color: white;
}

.container {
  padding: 20px;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 60vh; /* Adjust as needed */
}

.empty-state img {
  width: 150px; /* Adjust size as needed */
  margin-bottom: 20px;
}
</style>
<style scoped>
.bookmark-page {
  padding: 20px;
}

h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.bookmark-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.bookmark-item {
  height: 143px;
  background: var(--White, #FFF);
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  cursor: pointer;
}
.icon-wrapper{
    display: flex;
    justify-content: space-between;
}
.icon-wrapper img {
  width: 40px;
  height: 40px;
}

.bookmark-details p {
 color: var(--Neutral-Black, #383A3E);
 font-size: 16px;
 font-weight: 600;
  margin: 0 0 5px;
}

.count {
  font-size: 24px;
  font-weight: bold;
}
.bookmark-item {
  background-color: #ffffff;
  transition: background-color 0.3s ease;
}

.loading-background {
  background-color: #f3f3f3;
}

.icon {
  padding: 8px;
  border-radius: 6px;
}

.fade-out {
  opacity: 0;
  transform: scale(0.95) translateY(10px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}
</style>